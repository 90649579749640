header {
    height: 50vh;
    background-image: url("/public/Images/homeBackAbout.png");
}

.courses-section p {
    text-align: center;
    color: white;
    margin-top: 2.5vh;
}

.courses-section {
    background: linear-gradient(180deg, #47756f 0%, #13092e 100%);
}



#abt_txt {
    font-size: 12vh;
}
  

.team {
    margin-top: 2vh;
}

.ateam {
    justify-content: center;
    margin-bottom: 8vh;
}

.ateam h2 {
    font-size: 8vh;
}
