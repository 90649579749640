header {
  background-image: url("/public/Images/homeBack.png");
  background-size: cover; /* Adjust to your preference */
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  width: 100%;
}

.hero-section {
  height: 100vh;
  justify-content: space-between;
}

@media (max-width: 800px) {
    header {
      background-image: url("/public/Images/mobile-home.png");
    }
    .left {
      margin-top: 3lh;
      margin-left: 2lh;
      margin-right: 2lh;
    }

    .hero-section .left {
      justify-content: normal;
    }

    .testimonials-section {
        align-items: center;
    }
}